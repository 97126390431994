/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
 }
 
 /* Track */
 ::-webkit-scrollbar-track {
    background: #f1f1f1;
 }
 
 /* Handle */
 ::-webkit-scrollbar-thumb {
    background: rgb(216, 213, 213);
 }
 
 /* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
    background: rgb(175, 175, 175);
 }